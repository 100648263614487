import { Box, Center, Flex, Text, VStack } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { environment } from '../../../../environments/environment';
import { BrandSpinner, WindowAlert } from '../../../components';
import { getUserStrategy } from '../../../lib/apis';
import { IBaseResponse, UIComponentType } from '../../../lib/interfaces';
import { UserStrategyDto } from '../../../lib/types';
import { getSequentialOnboardingStep } from '../../../lib/utils/user-events';
import { ConnectCoinbaseForm } from '../components/Form/ConnectCoinbaseForm';
import { ConfirmationModal } from '../components/Modal/ConfirmationModal';
import { getUrlForNextOnboardEvent, useOnboardStore } from '../stores/onboard';

interface IConfirmationTextInterface {
  buttonText: string;
  continuationText: string;
}

// Text for confirmation state
const ConfirmationText: React.FC<IConfirmationTextInterface> = (props: IConfirmationTextInterface) => {
  return (
    <VStack>
      <Text color="brand.gray.500" my={5} textStyle="text-xl-regular" lineHeight={6}>
        You have been successfully connected to Coinbase.
      </Text>

      <Text as="span" color="gray.500" my={0} textStyle="text-xl-regular" lineHeight={6}>
        Your account will be invested at 9:30AM EST next day.
      </Text>

      <Text as="span" color="gray.500" my={0} textStyle="text-xl-regular" lineHeight={6}>
        You need to transfer in assets to the portfolio
      </Text>

      <Text as="span" color="gray.500" my={0} textStyle="text-xl-regular" lineHeight={6}>
        so you can start investing immediately.
      </Text>

      <Text color="brand.gray.500" my={5} textStyle="text-xl-regular">
        Click{' '}
        <Text as="span" color="brand.gray.500" textStyle="text-xl-semibold">
          “{props.buttonText}”
        </Text>{' '}
        {props.continuationText}
      </Text>
    </VStack>
  );
};

export const ConnectCoinbase = () => {
  const { state } = useLocation();
  console.log('connect coinbase: state passed via useLocation', state);
  const navigate = useNavigate();

  const [continueButtonText, setContinueButtonText] = useState('Continue');
  const [continueText, setContinueText] = useState('to continue');
  const [confirmed, setConfirmed] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const onboardState = useOnboardStore((state) => state.onboard);
  console.log('connect coinbase: onboard state', onboardState);

  // In StrictMode, the component code is executed twice to "help" you find bugs.
  // Whereas, in production, it only executes once.
  const isMountedRef = useRef(false);

  useEffect(() => {
    if (isMountedRef.current === true) return;
    isMountedRef.current = true;

    // React advises to declare the async function directly inside useEffect
    // Determine confirmation text based on next steps
    if (onboardState.onboardEvents !== null) {
      const nextStep = getSequentialOnboardingStep(UIComponentType.connect_coinbase, onboardState.onboardEvents);
      if (nextStep.name !== null) setContinueButtonText(nextStep.name);
      setContinueText(nextStep.continuationText);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onConfirmed = async () => {
    setConfirmed(true);

    try {
      const response = await getUserStrategy(onboardState.userStrategyId || '');
      if (response.successful) {
        onboardState.onboardEvents = response.payload.userStrategyOnboardingStatus.onboardingEvents;
        console.log('connect coinbase confirmation, onboardEvents', onboardState.onboardEvents);
        navigate(`${await getUrlForNextOnboardEvent(onboardState)}`, { state: { userData: state?.userData } });
        return;
      }

      setErrorMessage(
        (response.errorMessages?.length || 0) < 1
          ? `Unable to load onboarding options. Please contact ${environment.SupportName}`
          : response.errorMessages.toString()
      );
      setError(true);
      //
    } catch (error) {
      setErrorMessage(
        (error as AxiosError<IBaseResponse<UserStrategyDto>>)?.response?.data?.errorMessages?.toString() ||
          `Unable to update portfolio status. Please contact  ${environment.SupportName}.`
      );
      setError(true);
    }
  };

  return (
    <>
      <WindowAlert
        alertTitle="We are unable to process your request"
        alertMessage={errorMessage}
        alertStatus="error"
        isOpen={error}
        onClose={() => setError(false)}
      />

      <Flex flexDirection="column" justifyContent="start" w="100%">
        <Box w="100%">
          {confirmed && (
            <Center>
              <VStack mt="5%" w="600px">
                <Text color="gray.400" textStyle="text-md-regular" mb={8}>
                  We are updating your progress. Please wait...
                </Text>

                <BrandSpinner />
              </VStack>
            </Center>
          )}

          {!confirmed && (
            <>
              <ConfirmationModal
                isOpen={submitted && !confirmed}
                onClose={async () => onConfirmed()}
                onContinueButtonClick={async () => onConfirmed()}
                headerText="Congratulations!"
                continueButtonText={continueButtonText}
              >
                <VStack alignContent="stretch">
                  <ConfirmationText buttonText={continueButtonText} continuationText={continueText} />
                </VStack>
              </ConfirmationModal>

              <ConnectCoinbaseForm userStrategyId={onboardState.userStrategyId || ''} onSuccess={() => setSubmitted(true)} />
            </>
          )}
        </Box>
      </Flex>
    </>
  );
};
