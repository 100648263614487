export type UserInfoDto = {
  email: string;
  userName: string;
  isCurrentAgreementAccepted: boolean;
  isKYCVerified: boolean;
  isStrategySelected: boolean;
  isSuitabilityVerified: boolean;
};

export function isUserInfoDto(obj: any): obj is UserInfoDto {
  return (
    'email' in obj &&
    'userName' in obj &&
    'isCurrentAgreementAccepted' in obj &&
    'isKYCVerified' in obj &&
    'isStrategySelected' in obj &&
    'isSuitabilityVerified' in obj
  );
}
