import { axios } from '../hooks/axios';
import { IBaseResponse } from '../interfaces/IBaseResponse';
import { SuitabilityAnswerRequest, SuitabilityAnswerResponse, SuitabilityQuestionResponse } from '../types';

export const getSuitabilityQuestions = async (): Promise<IBaseResponse<SuitabilityQuestionResponse>> => {
  return axios.get('/suitability/questions');
};

export const saveSuitabilityAnswers = async (data: SuitabilityAnswerRequest): Promise<IBaseResponse<SuitabilityAnswerResponse>> => {
  return axios.post('/suitability/answers', data);
};
