import { Box, Flex, Image, Show, Spacer, VStack } from '@chakra-ui/react';
import React from 'react';
import { Outlet } from 'react-router-dom';

import logo from '../../../assets/dynamic-logo.svg';

export const AlternateLayout = () => {
  return (
    <Flex h="100vh" w="100vw">
      <Box flex={1} px={6}>
        <VStack>
          <Image src={logo} h={{ base: '20px', lg: '30px' }} w={{ base: '96.25px', lg: '58px' }} mt={{ base: '18px', lg: '58px' }} />

          <Spacer />
        </VStack>

        {/* This is the content for the route target */}
        <Outlet />

        <VStack>
          <Spacer />

          <Show above="md">
            <Box color="brand.gray.500" textStyle="text-sm-regular" w="600px" mt={5}>
              If you have any questions or would like to add any trusted contact to your account please reach out to contact@dynamic.com
            </Box>
          </Show>
        </VStack>
      </Box>
    </Flex>
  );
};
