import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Box,
  Button as ChakraButton,
  Flex,
  HStack,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';

import { StrategyDetailDto, StrategyDto } from '../../../../lib/types';

type StrategyInformationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  strategy: StrategyDto;
  strategyDetail: StrategyDetailDto | null;
};

const boxStyle = {
  boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.10)',
};

export const StrategyInformationModal = ({ isOpen, onClose, strategy, strategyDetail }: StrategyInformationModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxH="100vh" maxW="80vw" px={5} pt={8} pb={5} my={12}>
        <ModalCloseButton />
        <ModalBody borderRadius="8px" sx={{ boxStyle }} w="100%" h="100%">
          <Flex border="1px solid" borderColor="brand.borderLight" borderRadius={8} alignItems="stretch" px={4} py={5}>
            <VStack alignItems="stretch" w="100%">
              <Box mb={3}>
                <HStack w="100%" mb={3}>
                  <Image src={strategy.logoURL || ''} w={{ base: '40px', lg: '79px' }} h={{ base: '40px', lg: '79px' }} me={4}></Image>
                  <Text as="span" color="black" mb={0} textStyle={{ base: 'text-xl-medium', lg: 'display-sm-semibold' }}>
                    {strategy.name}
                  </Text>
                </HStack>

                <Text color="gray.500" textStyle={{ base: 'text-xs-regular', lg: 'text-sm-regular' }} mt={0} ml={0}>
                  {strategyDetail?.description ?? strategy.shortDescription}
                </Text>
              </Box>
              <Box w="100%">
                <Box w="100%" mb={6}>
                  <VStack mb={4} alignItems="stretch">
                    <HStack padding={0} mb={1}>
                      <Text textStyle="text-sm-medium" color="gray.500">
                        Manager:
                      </Text>
                      <Text as="span" textStyle="text-sm-medium" color="black">
                        {strategyDetail?.managerName ?? 'N/A'}
                      </Text>
                    </HStack>

                    {strategyDetail?.managerDescription && (
                      <Text as="span" textStyle="text-sm-medium" color="gray.500">
                        {strategyDetail!.managerDescription}
                      </Text>
                    )}

                    <HStack padding={0} mb={4}>
                      <Text textStyle="text-sm-medium" color="gray.500">
                        Inception Date:
                      </Text>
                      <Text as="span" textStyle="text-sm-medium" color="black">
                        {strategyDetail === null || strategyDetail.inceptionDate === null
                          ? 'N/A'
                          : (strategyDetail!.inceptionDate instanceof Date
                              ? strategyDetail!.inceptionDate
                              : new Date(strategyDetail!.inceptionDate)
                            ).toLocaleDateString('en', {
                              year: 'numeric',
                              day: '2-digit',
                              month: 'long',
                            })}
                      </Text>
                    </HStack>
                  </VStack>

                  <VStack alignItems="stretch" mb={4}>
                    <HStack padding={0} mb={1}>
                      <Text textStyle="text-sm-medium" color="gray.500">
                        Subscription Type
                      </Text>
                      <Text as="span" textStyle="text-sm-medium" color="black">
                        {strategyDetail === null || strategyDetail.pricing === null ? 'N/A' : strategyDetail!.pricing!.pricingType}
                      </Text>
                    </HStack>

                    <HStack padding={0} mb={1}>
                      <Text textStyle="text-sm-medium" color="gray.500">
                        Subscription Interval
                      </Text>
                      <Text as="span" textStyle="text-sm-medium" color="black">
                        {strategyDetail === null || strategyDetail.pricing === null ? 'N/A' : strategyDetail!.pricing!.interval}
                      </Text>
                    </HStack>

                    <HStack padding={0} mb={1}>
                      <Text textStyle="text-sm-medium" color="gray.500">
                        Price
                      </Text>
                      <Text as="span" textStyle="text-sm-medium" color="black">
                        {strategyDetail === null || strategyDetail.pricing === null || strategyDetail.pricing.pricePerUnit === null
                          ? 'N/A'
                          : strategyDetail!.pricing!.pricePerUnit.toLocaleString('en-US', {
                              style: 'currency',
                              currency: strategyDetail!.pricing!.currency,
                            })}
                      </Text>
                    </HStack>
                  </VStack>
                </Box>

                <ChakraButton
                  bgColor="white"
                  border="1px"
                  borderColor="blue.700"
                  borderRadius="8px"
                  paddingX={4}
                  paddingY="14px"
                  onClick={() => window.open(strategyDetail?.factSheetUrl ?? '', '_blank', 'rel=noopener noreferrer')}
                  type="button"
                >
                  <Text color="blue.700" textStyle="text-md-regular">
                    Fact Sheet
                  </Text>
                </ChakraButton>
              </Box>
            </VStack>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
