export type StrategyPricingDto = {
  strategyPriceId: string;
  pricingType: string;
  currency: string;
  pricePerUnit: number | null;
  interval: string;
};

export type StrategyDetailDto = {
  id: string;
  name: string;
  description: string;
  logoUrl: string;
  factSheetUrl: string;
  pricing: StrategyPricingDto | null;
  managerName: string | null;
  managerDescription: string | null;
  inceptionDate: Date | null;
};

export type StrategyDto = {
  id: string;
  name: string | null;
  managerName: string | null;
  shortDescription: string | null;
  logoURL: string | null;
};

export type StrategiesDto = {
  strategies: StrategyDto[] | null;
};

export const EmptyStrategy: StrategyDto = {
  id: '',
  name: '',
  managerName: '',
  shortDescription: '',
  logoURL: '',
};

export const NoStrategies: StrategyDto[] = [];

export const EmptyStrategies: StrategiesDto = {
  strategies: [],
};
