import { DefaultOnboardingEventDto, OnboardingEventDto, UserInfoDto } from '../types';

import { UIComponentSettings } from './ui-components';

export type OnboardNextStepDto = OnboardingEventDto & {
  targetUrl: string;
  continuationText: string;
};

export function determineLandingPage(data: UserInfoDto | null) {
  if (data === null || data === undefined) return '/auth/register';
  else if (!data.isCurrentAgreementAccepted) return '/msc/platformAgreement';
  else if (!data.isSuitabilityVerified) return '/msc/questionnaire';
  else if (!data.isStrategySelected) return '/onboard/selectStrategy';
  else return '/home';
}

export function getNextStep(events: OnboardingEventDto[]) {
  let targetUrl = '/home';
  let continuationText = '';
  let result: OnboardNextStepDto = { continuationText: continuationText, targetUrl: targetUrl, ...DefaultOnboardingEventDto };

  const nextStep = events.sort((e) => e.sequence).find((e) => e.isComplete === false);
  if (nextStep === undefined) return result;

  const uiComponent = nextStep.uiComponent || 'home';

  try {
    targetUrl = UIComponentSettings[uiComponent.toLowerCase()].targetUrl;
    continuationText = UIComponentSettings[uiComponent.toLowerCase()].continuationText;
  } catch (error) {
    console.log('Unable to retrieve configuration for UI Component', uiComponent);
  }

  result = { continuationText: continuationText, targetUrl: targetUrl, ...nextStep };
  return result;
}

export function getSequentialOnboardingStep(uiComponent: string, events: OnboardingEventDto[]) {
  let targetUrl = '/home';
  let continuationText = '';
  let result: OnboardNextStepDto = { continuationText: continuationText, targetUrl: targetUrl, ...DefaultOnboardingEventDto };

  const nextStep = events.sort((e) => e.sequence).find((e) => e.isComplete === false && e.uiComponent?.toLowerCase() !== uiComponent.toLowerCase());
  if (nextStep === undefined) return result;

  const nextUiComponent = nextStep.uiComponent || 'home';

  try {
    targetUrl = UIComponentSettings[nextUiComponent.toLowerCase()].targetUrl;
    continuationText = UIComponentSettings[nextUiComponent.toLowerCase()].continuationText;
  } catch (error) {
    console.log('Unable to retrieve configuration for UI Component', nextUiComponent);
  }

  result = { continuationText: continuationText, targetUrl: targetUrl, ...nextStep };
  return result;
}
