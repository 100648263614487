import { Box, Center, Flex, Heading, Text, VStack } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { environment } from '../../../../environments/environment';
import { BrandSpinner } from '../../../components';
import { UIComponentType } from '../../../lib/interfaces';
import { ConfigureBillingForm } from '../components/Form/ConfigureBillingForm';
import { FormButtons } from '../components/Form/FormButtons';
import { ConfirmationModal } from '../components/Modal/ConfirmationModal';
import { getUrlForNextOnboardEvent, useOnboardStore } from '../stores/onboard';

// Text for prompt state
const PromptText = () => {
  return (
    <Text color="brand.gray.500" mt={5} mb={2} textStyle="text-xl-regular">
      Let&apos;s set-up billing for your investment strategy.
    </Text>
  );
};

interface IConfirmationTextInterface {
  configuredBilling: boolean;
  buttonText: string;
  continuationText: string;
}

// TODO: Convert to modal like Connect Coinbase
// Text for confirmation state
const ConfirmationText: React.FC<IConfirmationTextInterface> = (props: IConfirmationTextInterface) => {
  if (!props.configuredBilling)
    return (
      <>
        <Text color="brand.gray.500" mt={5} mb={2} textStyle="text-xl-regular">
          Unable to set-up billing for your investment.
        </Text>

        <Text color="brand.gray.500" mb={5} textStyle="text-xl-regular">
          Click{' '}
          <Text as="span" color="brand.gray.500" textStyle="text-xl-semibold">
            “Try again”
          </Text>{' '}
          to try again or Contact {environment.SupportName} if the problem continues.
        </Text>
      </>
    );

  return (
    <>
      <Text color="brand.gray.500" my={2} textStyle="text-xl-regular">
        You have successfully configured billing.
      </Text>

      <Text color="brand.gray.500" my={5} textStyle="text-xl-regular">
        Click{' '}
        <Text as="span" color="brand.gray.500" textStyle="text-xl-semibold">
          “{props.buttonText}”
        </Text>{' '}
        {props.continuationText}
      </Text>
    </>
  );
};

export const ConfigureBilling = () => {
  const { state } = useLocation();
  console.log('configure billing: state passed via useLocation', state);
  const navigate = useNavigate();

  const [confirmed, setConfirmed] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const onboardState = useOnboardStore((state) => state.onboard);
  console.log('configure billing: onboard state', onboardState);

  // In StrictMode, the component code is executed twice to "help" you find bugs.
  // Whereas, in production, it only executes once.
  const isMountedRef = useRef(false);

  useEffect(() => {
    if (isMountedRef.current === true) return;
    isMountedRef.current = true;

    console.log('configure billing useEffect: onboard state', onboardState);

    // React advises to declare the async function directly inside useEffect

    // This will be an I-Frame
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: This will go away after the demo
  const onConfirmed = async () => {
    setConfirmed(true);

    const onboardEvent = onboardState.onboardEvents?.find((c) => c.name?.toLowerCase() === UIComponentType.billing);
    if (onboardEvent !== null && onboardEvent !== undefined) {
      onboardEvent.completedDate = new Date();
      onboardEvent.isComplete = true;
    }

    const nextUrl = await getUrlForNextOnboardEvent(onboardState);
    console.log('next step Url', nextUrl);

    navigate(`${await getUrlForNextOnboardEvent(onboardState)}`, { state: { userData: state?.userData } });
  };

  return (
    <Flex flexDirection="column" justifyContent="start" w="100%">
      <Heading textStyle={{ base: 'display-md-bold', lg: 'display-2xl-bold' }} color="brand.black">
        Configure Billing [Placeholder]
      </Heading>

      <Box w="100%">
        {confirmed && (
          <Center>
            <VStack mt="5%" w="600px">
              <Text color="gray.400" textStyle="text-md-regular" mb={8}>
                We are updating your progress. Please wait...
              </Text>

              <BrandSpinner />
            </VStack>
          </Center>
        )}

        {!confirmed && (
          <>
            <ConfirmationModal
              isOpen={submitted && !confirmed}
              onClose={async () => onConfirmed()}
              onContinueButtonClick={async () => onConfirmed()}
              headerText="Congratulations, you're all done!"
              continueButtonText="Home"
            >
              <VStack alignContent="stretch">
                <ConfirmationText configuredBilling={true} buttonText="Home" continuationText="to continue to your dashboard" />
              </VStack>
            </ConfirmationModal>

            <PromptText />

            <FormButtons displayBackButton={false} nextButtonText="Continue" isSubmitting={false} manualSubmit={() => setSubmitted(true)} />

            <ConfigureBillingForm onSuccess={() => setSubmitted(true)} />
          </>
        )}
      </Box>
    </Flex>
  );
};
