import { Box, Center, Flex, Text, VStack } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { environment } from '../../../../environments/environment';
import { BrandSpinner, WindowAlert } from '../../../components';
import { addUserStrategy, UserStrategyOnboardRequest } from '../../../lib/apis';
import { IBaseResponse, UIComponentType } from '../../../lib/interfaces';
import { StrategyDto, EmptyStrategy, UserStrategyDto } from '../../../lib/types';
import { getSequentialOnboardingStep } from '../../../lib/utils/user-events';
import { SelectStrategyForm } from '../components/Form/SelectStrategyForm';
import { ConfirmationModal } from '../components/Modal/ConfirmationModal';
import { ConfirmationText } from '../components/Prompts/SelectStrategyPrompts';
import { getUrlForNextOnboardEvent, useOnboardStore } from '../stores/onboard';

export const SelectStrategy = () => {
  const { state } = useLocation();
  console.log('select strategy: state passed via useLocation', state);
  const navigate = useNavigate();

  const [continueButtonText, setContinueButtonText] = useState('Continue');
  const [continueText, setContinueText] = useState('to continue');
  const [confirmed, setConfirmed] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [selectedStrategy, setSelectedStrategy] = useState(EmptyStrategy);

  // In StrictMode, the component code is executed twice to "help" you find bugs.
  // Whereas, in production, it only executes once.
  const isMountedRef = useRef(false);

  // Triggered more than once - use a reference variable to determine if component is mounted
  useEffect(() => {
    if (isMountedRef) return; // So we do not wipe out onboard state!@

    setOnboard({
      strategy: null,
      onboardEvents: null,
      userStrategyId: null,
    });

    // Determine confirmation text based on next steps
    if (onboardState.onboardEvents !== null) {
      const nextStep = getSequentialOnboardingStep(UIComponentType.strategy, onboardState.onboardEvents);
      if (nextStep.name !== null) setContinueButtonText(nextStep.name);
      setContinueText(nextStep.continuationText);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setOnboard = useOnboardStore((state) => state.setOnboard);
  const onboardState = useOnboardStore((state) => state.onboard);

  // User confirmed strategy selection
  const onConfirmed = async () => {
    setConfirmed(true);

    try {
      const request: UserStrategyOnboardRequest = {
        strategyId: selectedStrategy.id,
      };
      const response = await addUserStrategy(request);

      if (!response.successful || response.payload.userStrategyOnboardingStatus.onboardingEvents.length < 1) {
        setErrorMessage(
          (response.errorMessages?.length || 0) < 1
            ? `Unable to load onboarding options. Please contact ${environment.SupportName}`
            : response.errorMessages.toString()
        );
        setError(true);
      } else {
        onboardState.userStrategyId = response.payload.userStrategyId;
        onboardState.onboardEvents = response.payload.userStrategyOnboardingStatus.onboardingEvents;
        console.log('select strategy confirmation, onboardEvents', onboardState.onboardEvents);
        navigate(`${await getUrlForNextOnboardEvent(onboardState)}`, { state: { userData: state?.userData } });
        return;
      }
    } catch (error) {
      setErrorMessage(
        (error as AxiosError<IBaseResponse<UserStrategyDto>>)?.response?.data?.errorMessages?.toString() ||
          `Failed to save strategy selection. Please contact  ${environment.SupportName}.`
      );
      setError(true);
    }
  };

  // User backed out
  const onReset = async () => {
    setConfirmed(false);
    setSubmitted(false);
  };

  // User selected strategy for investment
  const onStrategySelected = async (values: StrategyDto) => {
    setSubmitted(true);
    setSelectedStrategy(values); // This is not set immediately?!
    onboardState.strategy = values;
  };

  return (
    <>
      <WindowAlert
        alertTitle="We are unable to update your credentials"
        alertMessage={errorMessage}
        alertStatus="error"
        isOpen={error}
        onClose={() => setError(false)}
      />

      <Flex flexDirection="column" justifyContent="start" w={{ base: '80vw', lg: '100%' }}>
        <Text mb={{ base: '20px', lg: '0rem' }} textStyle={{ base: 'text-md-semibold', lg: 'display-md-semibold' }} color="brand.black">
          Select your investment strategy
        </Text>

        <Box w={{ base: '80vw', lg: '100%' }}>
          {confirmed && (
            <Center>
              <VStack mt="5%" w={{ base: '80vw', lg: '600px' }}>
                <Text color="gray.400" textStyle={{ base: 'text-xs-regular', lg: 'text-md-regular' }} mb={8}>
                  We are saving your selection. Please wait...
                </Text>

                <BrandSpinner />
              </VStack>
            </Center>
          )}

          {!confirmed && (
            <Box mt="2%" w={{ base: '80vw', lg: '100%' }}>
              <ConfirmationModal
                isOpen={submitted && !confirmed}
                onBackButtonClick={() => onReset()}
                onClose={() => onConfirmed()}
                onContinueButtonClick={() => onConfirmed()}
                headerText="Congratulations"
                backButtonText="Back"
                continueButtonText={continueButtonText}
              >
                <VStack alignContent="stretch">
                  <ConfirmationText
                    strategyName={selectedStrategy.name || ''}
                    logoURL={selectedStrategy.logoURL || ''}
                    buttonText={continueButtonText}
                    continuationText={continueText}
                  />
                </VStack>
              </ConfirmationModal>

              <Box w="100%">
                <SelectStrategyForm onSuccess={(values) => onStrategySelected(values.strategy)} />
              </Box>
            </Box>
          )}
        </Box>
      </Flex>
    </>
  );
};
